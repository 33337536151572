//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4plugins_forceDirected from '@amcharts/amcharts4/plugins/forceDirected';
import am4themes_dark from "@amcharts/amcharts4/themes/dark";


import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import { mapGetters, mapActions } from 'vuex';
import eventBus from '@/plugins/eventBus';
import * as _ from 'underscore';

am4core.useTheme(am4themes_animated);
am4core.options.autoDispose = true;

export default {
  name: 'topics',
  data() {
    return {
      showChart: true,
      showInnerLoading: false,
      Force_Directed_Tree_data: [],
      showConnections: false,
      isCall: false,
    };
  },
  computed: {
    ...mapGetters({
      getFilterDate: 'getFilterDate',
      currentAIAccount: 'getCurrentAIAccount',
      currentUser: 'getCurrentUser',
      darkTheme: 'getDarkTheme'
    }),
  },
  methods: {
    ...mapActions({
      requestConversationsTopicParameters: 'requestConversationsTopicParameters',
      requestConversationsFlow2All: 'requestConversationsFlow2All',
      generateXMLFile: 'generateXMLFile',
    }),
    generateForceDirectedTree() {
      const that = this;
      if(this.forceDirectedTree){
        this.forceDirectedTree.dispose()
      }
      if(this.darkTheme === true){
        am4core.useTheme(am4themes_dark);
      }else{
        am4core.unuseTheme(am4themes_dark)
      }
      // create chart
      const chart = am4core.create(
        that.$refs.forceDirectedTree,
        am4plugins_forceDirected.ForceDirectedTree,
      );

      const networkSeries = chart.series.push(
        new am4plugins_forceDirected.ForceDirectedSeries(),
      );

      chart.legend = new am4charts.Legend();
      chart.legend.maxWidth = 400;
      chart.legend.position = 'right';
      chart.legend.scrollable = true;
      chart.legend.valueLabels.template.align = 'right';
      chart.legend.valueLabels.template.textAlign = 'start';
      chart.data = that.Force_Directed_Tree_data;

      networkSeries.dataFields.value = 'value';
      networkSeries.dataFields.name = 'name';
      networkSeries.dataFields.children = 'children';
      networkSeries.nodes.template.tooltipText = '{name}:{value}';
      networkSeries.nodes.template.fillOpacity = 1;
      networkSeries.nodes.template.label.text = '{name}';
      networkSeries.fontSize = 10;

      // this makes labels to be hidden if they don't fit
      networkSeries.links.template.strokeWidth = 1;
      networkSeries.minRadius = 18;
      networkSeries.maxRadius = 48;
      const hoverState = networkSeries.links.template.states.create('hover');
      hoverState.properties.strokeWidth = 3;
      hoverState.properties.strokeOpacity = 1;

      networkSeries.nodes.template.events.on('over', (event) => {
        event.target.dataItem.childLinks.each((link) => {
          link.isHover = true;
        });
        if (event.target.dataItem.parentLink) {
          event.target.dataItem.parentLink.isHover = true;
        }
      });

      networkSeries.nodes.template.events.on('out', (event) => {
        event.target.dataItem.childLinks.each((link) => {
          link.isHover = false;
        });
        if (event.target.dataItem.parentLink) {
          event.target.dataItem.parentLink.isHover = false;
        }
      });
      networkSeries.nodes.template.label.text = '{name}';
      networkSeries.fontSize = 8;
      networkSeries.maxLevels = 1;
      networkSeries.maxRadius = am4core.percent(6);
      networkSeries.manyBodyStrength = -16;
      networkSeries.nodes.template.label.hideOversized = true;
      networkSeries.nodes.template.label.truncate = true;
      that.forceDirectedTree = chart;
      // Enable export
      chart.exporting.menu = new am4core.ExportMenu();
      chart.exporting.filePrefix = `EVO_Dynamics_report-${that.currentAIAccount.name}-Topic drilldown-${that.getFilterDate.start}-to-${that.getFilterDate.end}`;
    },
    generateForceDirectedNetwork() {
      const that = this;
      if(this.forceDirectedNetwork){
        this.forceDirectedNetwork.dispose()
      }
      if(this.darkTheme === true){
        am4core.useTheme(am4themes_dark);
      }else{
        am4core.unuseTheme(am4themes_dark)
      }
      const chart = am4core.create(
        that.$refs.forceDirectedNetwork,
        am4plugins_forceDirected.ForceDirectedTree,
      );

      const networkSeries = chart.series.push(
        new am4plugins_forceDirected.ForceDirectedSeries(),
      );
      networkSeries.dataFields.linkWith = 'linkWith';
      networkSeries.dataFields.name = 'name';
      networkSeries.dataFields.id = 'name';
      networkSeries.dataFields.value = 'value';
      networkSeries.dataFields.children = 'children';

      networkSeries.nodes.template.label.text = '{name}';
      networkSeries.fontSize = 8;
      networkSeries.linkWithStrength = 0;

      const nodeTemplate = networkSeries.nodes.template;
      nodeTemplate.tooltipText = '{name}';
      nodeTemplate.fillOpacity = 1;
      nodeTemplate.label.hideOversized = true;
      nodeTemplate.label.truncate = true;

      const linkTemplate = networkSeries.links.template;
      linkTemplate.strokeWidth = 1;
      const linkHoverState = linkTemplate.states.create('hover');
      linkHoverState.properties.strokeOpacity = 1;
      linkHoverState.properties.strokeWidth = 2;

      nodeTemplate.events.on('over', (event) => {
        const { dataItem } = event.target;
        dataItem.childLinks.each((link) => {
          link.isHover = true;
        });
      });

      nodeTemplate.events.on('out', (event) => {
        const { dataItem } = event.target;
        dataItem.childLinks.each((link) => {
          link.isHover = false;
        });
      });

      that.forceDirectedNetwork = chart;
      // Enable export
      chart.exporting.menu = new am4core.ExportMenu();
      chart.exporting.filePrefix = `EVO_Dynamics_report-${that.currentAIAccount.name}-Topic drilldown-${that.getFilterDate.start}-to-${that.getFilterDate.end}`;

      // chart.legend = new am4charts.Legend();
      networkSeries.data = that.Force_Directed_Tree_data;
    },
    onDateUpdate(date) {
      const that = this;
      if (that.isValid(date.start) && that.isValid(date.end)) {
        const data = {
          aiAccount: that.currentAIAccount.id,
          startdate: date.start,
          enddate: date.end,
          from: 'onDateUpdate',
        };
        that.isCall = false;
        that.updatetopicsDrilledData(data);
      }
    },
    updatetopicsTreeData(data) {
      const that = this;
      if (!that.isCall) {
        that.showChart = true;
        that.showInnerLoading = true;
        that.showPagination = false;
        that.isCall = true;
        that.requestConversationsTopicParameters(data)
          .then((res) => {
            that.Force_Directed_Tree_data = res.result;
            that.showInnerLoading = false;
            setTimeout(() => {
              that.generateForceDirectedTree();
            }, 100);
          })
          .catch((err) => {
            that.showChart = false;
            that.showInnerLoading = false;
          })
          .finally(() => {
            that.isCall = false;
          });
      }
    },
    updatetopicsNetworkData(data) {
      const that = this;
      if (!that.isCall) {
        that.showChart = true;
        that.showInnerLoading = true;
        that.showPagination = false;
        that.isCall = true;
        that.requestConversationsFlow2All(data).then((res) => {
          that.Force_Directed_Tree_data = res.result;
          that.showInnerLoading = false;
          setTimeout(() => {
            that.generateForceDirectedNetwork();
          }, 100);
        })
          .catch((err) => {
            that.showChart = false;
            that.showInnerLoading = false;
          })
          .finally(() => {
            that.isCall = false;
          });
      }
    },
    updatetopicsDrilledData(data) {
      const that = this;
      if (that.forceDirectedTree) {
        that.forceDirectedTree.dispose();
      }
      if (that.forceDirectedNetwork) {
        that.forceDirectedNetwork.dispose();
      }
      if (that.showConnections == true) {
        that.updatetopicsNetworkData(data);
      } else {
        that.updatetopicsTreeData(data);
      }
    },
    onAccountUpdate(account) {
      const that = this;
      const data = {
        aiAccount: account.id,
        startdate: that.getFilterDate.start,
        enddate: that.getFilterDate.end,
        from: 'onAccountUpdate',
      };
      that.isCall = false;
      that.updatetopicsDrilledData(data);
    },
    isValid(value) {
      if (
        value === undefined
        || value === null
        || (typeof value === 'object' && Object.keys(value).length === 0)
        || (typeof value === 'string' && value.trim().length === 0)
      ) {
        return false;
      }
      return true;
    },
    exportSectionToXLS(reportType) {
      const that = this;
      that.showInnerLoading = true;
      const data = {
        type: reportType,
        aiAccount: that.currentAIAccount.id,
        startdate: that.getFilterDate.start,
        enddate: that.getFilterDate.end,
      };
      that.generateXMLFile(data);
      setTimeout(() => {
        that.showInnerLoading = false;
      }, 1000);
    },
  },
  watch: {
    showConnections(newVal) {
      const that = this;
      const data = {
        aiAccount: that.currentAIAccount.id,
        startdate: that.getFilterDate.start,
        enddate: that.getFilterDate.end,
        from: 'watch',
      };
      that.isCall = false;
      that.updatetopicsDrilledData(data);
    },
    darkTheme(){
      const that = this;
      const data = {
        aiAccount: that.currentAIAccount.id,
        startdate: that.getFilterDate.start,
        enddate: that.getFilterDate.end,
        from: 'darkTheme watch',
      };
      that.isCall = false;
      that.updatetopicsDrilledData(data);
    }
  },
  mounted() {
    const that = this;
    const data = {
      aiAccount: that.currentAIAccount.id,
      startdate: that.getFilterDate.start,
      enddate: that.getFilterDate.end,
      from: 'mounted',
    };
    that.updatetopicsDrilledData(data);
  },
  created() {
    eventBus.$on('updateDate', this.onDateUpdate);
    eventBus.$on('updateAIAccount', this.onAccountUpdate);
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
    eventBus.$off('updateDate', this.onDateUpdate);
    eventBus.$off('updateAIAccount', this.onAccountUpdate);
  },
  destroyed() {
    eventBus.$off('updateDate', this.onDateUpdate);
    eventBus.$off('updateAIAccount', this.onAccountUpdate);
  },
};
